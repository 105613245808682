import React from "react";
import './PostShare.css'

import {
  FacebookShareButton,
  TwitterShareButton,
  FacebookShareCount,
  PinterestShareButton,
  PinterestShareCount,
  FacebookIcon,
  TwitterIcon,
  PinterestIcon
} from "react-share";

import config from "../../content/settings/config";

class PostShare extends React.Component {
  render() {
    const { title, featuredImage, slug } = this.props;
    const url = config.siteUrl + "" + slug;
    
    const media = (featuredImage===null)? "" : config.siteUrl +featuredImage.childImageSharp.resize.src;

    const iconSize = 48;
    const filter = count => (count > 0 ? count : "");
    console.log(media);
    return (
      <div className="share">
          <span className="label">SHARE</span>
          <div className="share-btn-list">
          <TwitterShareButton url={url} title={"Check out "+title+" on Serious.Eating"}>
            <TwitterIcon size={iconSize} />
          </TwitterShareButton>
          <PinterestShareButton media={media} url={url} title={"Share "+title}>
            <PinterestIcon size={iconSize} />
            <PinterestShareCount url={url}>
              {count => <div className="share-count">{filter(count)}</div>}
            </PinterestShareCount>
          </PinterestShareButton>
          <FacebookShareButton
            url={url}
            title={"Share "+title}
            quote={`${title} - "Review By Serious Eats"`} // - ${excerpt}`} 
            aria-label="Facebook share"
          >
            <FacebookIcon size={iconSize} />
            <FacebookShareCount url={url}>
              {count => <div className="share-count">{filter(count)}</div>}
            </FacebookShareCount>
          </FacebookShareButton>
        </div>
        </div>
    );
  }
}

export default PostShare;
