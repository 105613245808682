import React, { Component, Fragment } from 'react'
import PostShare from '../components/PostShare'
import PropTypes from "prop-types";

import './PostFooter.css'

class PostFooter extends Component {

    render() {
        const { title, featuredImage, slug } = this.props;
        console.log(featuredImage);
        return (
            <Fragment>
               {featuredImage &&  <PostShare title={title} slug={slug} featuredImage={featuredImage}/> }
            </Fragment>
        )
    }

}

PostFooter.propTypes = {
    title: PropTypes.string.isRequired,
    slug: PropTypes.string.isRequired,
    featuredImage: PropTypes.object.isRequired,
  };

export default PostFooter;